import { useStore } from '@nanostores/react';
import { cartData } from '@stores/cartStore.js';

;

import Highlight from '../Highlight/Highlight.jsx';

const PRODUCT_BASE_URL = "/products/";

const Product = ({ product, query }) => {
    const $cartData = useStore(cartData);

    // // console.log product', product)
    let queryLower = query.toString().toLowerCase()

	const variants = product.variants;
	let matchingVariant = variants[0];
	console.log("product: ", product);
	console.log("variants: ", variants);

	// if (variants && variants.length > 1) {
	// 	variants.find((variant) => {
	// 		console.log("queryLower", queryLower);
	// 		console.log("variant.title", variant.title);
	// 		console.log(
	// 			"variant.title.toString().toLowerCase().indexOf(queryLower)",
	// 			variant.title.toString().toLowerCase().indexOf(queryLower)
	// 		);
	// 		return variant.title.toString().toLowerCase().indexOf(queryLower) > -1;
	// 	});
	// } else if (variants && variants.length === 1) {
	// 	matchingVariant = variants[0];
	// }

	console.log(
		"matchingVariant in search product",
		matchingVariant,
		matchingVariant.id
	);

	if (product.title.includes("Double Ruffle")) {
		// // console.log matchingVariant', matchingVariant)
	}

	let title = product.title.split("_")[0];
	let handle = product.handle.split("_")[0];
	// handle = handle.replace(/trunk-sale-/g, '')
	// handle = handle.replace(/sale-/g, '')
	// handle = handle.replace(/-1/g, '')
	// handle = handle.replace(/-2/g, '')
	// handle = handle.replace(/-3/g, '')
	// handle = handle.replace(/-4/g, '')
	// handle = handle.replace(/-5/g, '')

	const handleItemClick = (e) => {
		if (matchingVariant) {
				chord.trackProductClicked({
						cart: $cartData,
						listId: 'search',
						product: {
								product,
								variantId: matchingVariant.id
						}
				})
		}
}

	return (
		<li className="product flex flex-col min-h-[220px]" key={product.id} onClick={(e) => handleItemClick(e)}>
			{matchingVariant ? (
				<a href={PRODUCT_BASE_URL + handle + "?variant=" + matchingVariant.id}>
					<div className="product-image">
						{matchingVariant && matchingVariant.image ? (
							<img
								className="variant-img"
								src={matchingVariant.image}
								alt={title}
								key={matchingVariant.id}
							/>
						) : (
							<img
								className="product-img"
								src={
									product.images_info && product.images_info.length
										? product.images_info[0].src
										: ""
								}
								alt={title}
								key={product.id}
							/>
						)}
					</div>
				</a>
			) : (
				<div className="product-image">
					<a href={PRODUCT_BASE_URL + handle}>
						<img
							className="product-img"
							src={
								product.images_info && product.images_info.length
									? product.images_info[0].src
									: ""
							}
							alt={title}
							key={product.id}
						/>
					</a>
				</div>
			)}
			<div className="product-wrap">
				<h3 className="product-title">
					{matchingVariant && matchingVariant.length > 0 ? (
						<a
							href={
								PRODUCT_BASE_URL + handle + "?variant=" + matchingVariant.id
							}
						>
							<Highlight search={query}>{title}</Highlight>
						</a>
					) : (
						<a className="font-normal text-sm" href={PRODUCT_BASE_URL + handle}>
							<Highlight search={query}>{title}</Highlight>
						</a>
					)}
				</h3>
				<div className="product-price">
					<span className="price text-sm">${product.price_max.toFixed(2)}</span>
				</div>
			</div>
		</li>
	);
};

export default Product;
