import React, { useState, useEffect, useRef } from 'react'
import { useStore } from '@nanostores/react'
import { isSearchOpen, searchTerm } from '@stores/searchStore'


import SearchFlyout from '../Search/SearchFlyout/SearchFlyout.jsx'
import SearchIcon from './SearchIcon/SearchIcon.jsx'
import SearchStatic from '../Search/SearchStatic/SearchStatic.jsx'

import staticData from '../Search/SearchData/staticData.js'

// console.log('staticData', staticData)

import { trimLeadingSpaces } from '@utils/utils.js'

const BOOST_URL =
    'https://services.mybcapps.com/bc-sf-filter/search/suggest?shop=monicaandandyonline.myshopify.com&page_limit=5&collection_limit=5&suggestion_limit=5&product_limit=8&product_available=true&variant_available=true&re_run_if_typo=true&sort=relevance&q='

const freeRobes = [7265594540094,7265596473406,7265596604478,7265596964926,7265596997694,40242146738238,40242161319998,40242161352766,40242161385534,40242161418302,40242161516606,40242161549374,40242161582142,40242162532414,40242162565182,40242162597950,40242162630718,40242162663486,40242162696254]

const Search = () => {
    const $isSearchOpen = useStore(isSearchOpen || false)
    const $searchTerm = useStore(searchTerm || '')

    const [query, setQuery] = useState($searchTerm.q || '')
    const [suggestions, setSuggestions] = useState([])
    const [pages, setPages] = useState([])
    const [collections, setCollections] = useState([])
    const [products, setProducts] = useState([])

    const inputElement = useRef(null)

    const onChangeHandler = (e) => {
        // console.log('onChangeHandler', e.target.value)
        // remove leading and trailing spaces from text entry
        let text = trimLeadingSpaces(e.target.value)
        setQuery(text)

        let searchObj = {
            ...searchTerm.get(),
            q: text,
        }

        searchTerm.set(searchObj)
    }

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion)

        let searchObj = {
            ...searchTerm.get(),
            q: suggestion,
        }

        searchTerm.set(searchObj)
    }

    const handleClearSearch = () => {
        setQuery('')

        let searchObj = {
            ...searchTerm.get(),
            q: '',
        }
        searchTerm.set(searchObj)
    }

    const handleCancelSearch = () => {
        if (isSearchOpen) {
            isSearchOpen.set(false)
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            isSearchOpen.set(false)
            chord.trackProductsSearched({ currency: 'USD', query }).then(() => {
                window.location.href = `/search?q=` + $searchTerm.q
            })
        }
        if (e.key === 'Escape') {
            handleCancelSearch()
        }
    }

    useEffect(() => {
        if ($searchTerm && searchTerm.q && searchTerm.q !== '') {
            setQuery($searchTerm.q)
        } else {
            setQuery('')
        }
    }, [$searchTerm])

    useEffect(() => {
        let searchObj = {
            ...searchTerm.get(),
            q: '',
        }
        searchTerm.set(searchObj)
        setQuery('')
    }, [])

    useEffect(() => {
        // console.log('query in useEffect', query)
        setQuery(query)
        if (query && query.length > 0) {
            let searchTerm = encodeURIComponent($searchTerm.q)
            let url = `${BOOST_URL}${searchTerm}`
            // console.log('url', url)
            fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    console.log('res from search', res)
                    setSuggestions(res.suggestions)
                    setPages(res.pages)
                    setCollections(res.collections)

                    const products = res.products.filter((p) => {
                        return freeRobes.indexOf(p.id) === -1
                    })
                    setProducts(products)
                })
        }
    }, [query])

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus()
        }
    }, [])

    useEffect(() => {
        const keyDownHandler = (e) => {
            if (e.key === 'Escape') {
                event.preventDefault()

                handleCancelSearch()
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [])

    useEffect(() => {
        //setup before functions
        let typingTimer //timer identifier
        let doneTypingInterval = 2000 //time in ms (5 seconds)
        let myInput = document.getElementById('boost-search-input')

        //on keyup, start the countdown
        myInput.addEventListener('keyup', () => {
            clearTimeout(typingTimer)
            if (myInput.value) {
                typingTimer = setTimeout(doneTyping, doneTypingInterval)
            }
        })

        //user is "finished typing," do something
        function doneTyping() {
            //do something
            dataLayer = window.dataLayer || []
            dataLayer.push({
                event: 'dl_search_term',
                search_term: query,
            })
        }
    }, [$isSearchOpen])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (query) {
                chord.trackProductsSearched({ currency: 'USD', query })
            }
        }, 2000)
        return () => clearTimeout(timer)
    }, [query])

    return (
        <div
            className={`App Boost-InstantSearch-App  ${
                $isSearchOpen
                    ? 'fixed top-[60px] mx-auto w-[96vw] lg:w-[80vw] bg-white shadow-md z-50 overflow-y-auto max-h-[80vh]'
                    : 'hidden'
            }`}
        >
            <div className="search-input-outer h-16 w-full border bottom-1 border-stone-300">
                <div className="search-input-inner flex items-center p-2 h-full relative ml-1 lg:ml-4">
                    <span className="search-icon w-5 absolute left-6">
                        <SearchIcon />
                    </span>
                    <input
                        className={`w-full h-full mr-4 pl-10 pr-3 border-2 border-oat text-xl text-ash appearance-none ${
                            query && query.q ? 'text-2xl' : 'font-normal'
                        }`}
                        id="boost-search-input"
                        type="search"
                        placeholder="Search here..."
                        value={$searchTerm.q}
                        onChange={onChangeHandler}
                        autoFocus
                        ref={inputElement}
                        onKeyUp={(e) => handleKeyPress(e)}
                        inputMode="search"
                        autoCapitalize="none"
                    />
                    {$searchTerm && $searchTerm.q ? (
                        <div
                            className={`search-clear flex items-center absolute right-[90px] lg:right-[130px] ${
                                $searchTerm.q.length > 18
                                    ? 'hidden lg:flex'
                                    : ''
                            }`}
                        >
                            <div
                                className="clear-search"
                                onClick={handleClearSearch}
                            >
                                <span className="clear-text mr-2 text-sm cursor-pointer text-oat hover:text-flint relative -top-[1px]">
                                    Clear
                                </span>
                            </div>
                        </div>
                    ) : null}

                    <div className="cancel-search bg-slate px-3 rounded-lg mr-2">
                        <span
                            className="cancel-icon flex items-center text-flint hover:text-ash relative  cursor-pointer text-xs lg:text-base"
                            onClick={handleCancelSearch}
                        >
                            <span className="text-2xl -top-[2px] relative lg:mr-1">
                                &times;
                            </span>{' '}
                            <span className="hidden lg:block">Close</span>
                        </span>
                    </div>
                </div>
            </div>
            {query ? (
                <div className="search-results-outer">
                    <div className="search-results-inner flex items-center w-full">
                        {console.log('query here', query)}
                        <SearchFlyout
                            suggestions={suggestions}
                            handleSuggestionClick={handleSuggestionClick}
                            collections={collections}
                            pages={pages}
                            products={products}
                            query={query}
                        />
                    </div>
                </div>
            ) : null}
            {!query ? (
                <SearchStatic
                    staticData={staticData}
                    handleSuggestionClick={handleSuggestionClick}
                />
            ) : null}
        </div>
    )
}

export default Search
